.App {
  text-align: center;
  background: white;
  width: 80%;
  margin: 0 auto;
}

.Div {
  width: 80%;
  margin: 0 auto;
}
.P {
  font-size: 16px;
}
.PLink {
  font-weight: bold;
  cursor: pointer;
}